module.exports = {

    init: function () {


          $( ".test" ).on( "click", function() {
            alert("The test div was clicked.");
          });

          $( ".hamburger" ).on( "click", function() {
            $( ".hamburger" ).toggleClass( "is-active" );
            $( ".mobile-menu" ).toggleClass( "show" );
            $( "header" ).toggleClass( "swap" );
          });


        // Check display type
        if ("ontouchstart" in document.documentElement) {
            $("body").addClass("is-touch");
            let vh = window.innerHeight * 0.01; // iOS screen height fix
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        } else {
            $("body").addClass("not-touch");

        }

    }

};